<template>
    <div class="pa-0">
        <div class="interaction-section-body1">
          <hb-form label="Send PDF Statements" :active.sync="active" description="Email invoice statements as pdf attachment" @cancel="handleEmittedCancel" @save="handleEmittedSave('sendInvoicePdfAttachment')" editable :empty="false" tooltipBody="Emails will contain invoice pdf attachments rather than html text">
            <template v-slot:display>
              <v-col class="ma-0 pa-0" cols="12" sm="12">{{setting.sendInvoicePdfAttachment ? 'Yes' : 'No'}}</v-col>
            </template>
            <template v-slot:edit>
              <div>
                <hb-switch right v-model="setting.sendInvoicePdfAttachment" :label="setting.sendInvoicePdfAttachment ? 'Yes' : 'No'"></hb-switch>
              </div>
            </template>
          </hb-form>
        </div>
        <div class="interaction-section-body1" v-if="setting.sendInvoicePdfAttachment">
          <hb-form label="Group Contact Statements" :active.sync="active" description="Email single statement for all leases of a contact" @cancel="handleEmittedCancel" @save="handleEmittedSave('groupContactLeasesStatements')" editable :empty="false" tooltipBody="For grouping, leases should have same property, same payment cycle and same due date / payment cycle start date">
            <template v-slot:display>
              <v-col class="ma-0 pa-0" cols="12" sm="12">{{setting.groupContactLeasesStatements ? 'Yes' : 'No'}}</v-col>
            </template>
            <template v-slot:edit>
              <div>
                <hb-switch right v-model="setting.groupContactLeasesStatements" :label="setting.groupContactLeasesStatements ? 'Yes' : 'No'"></hb-switch>
              </div>
            </template>
          </hb-form>
        </div>
        <div class="interaction-section-body1" v-if="setting.sendInvoicePdfAttachment">
          <hb-form label="Marketing Content Details" :active.sync="active" description="Allow Marketing Content" @cancel="handleEmittedCancel" @save="handleEmittedSaveMarketing" editable :empty="false">
            <template v-slot:display>
                <v-col class="ma-0 pa-0" cols="12" sm="12">{{setting.pdfStatementAllowMarketing ? 'Yes' : 'No'}}</v-col>

                <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing"> {{'Content Type'}} </v-col>
                <v-col class="hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing"> {{marketingContentTypeText}} </v-col>

                <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing"> {{'Content Heading'}} </v-col>
                <v-col class="hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing">{{setting.pdfStatementMarketingContent.content_heading}}</v-col>

                <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'text' || setting.pdfStatementMarketingContent.content_type === 'both')"> {{'Content Text'}} </v-col>
                <v-col class="hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'text' || setting.pdfStatementMarketingContent.content_type === 'both')">{{setting.pdfStatementMarketingContent.content_text}}</v-col>

                <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'img' || setting.pdfStatementMarketingContent.content_type === 'both')">{{'Content Image'}}</v-col>
                <v-col class="hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'img' || setting.pdfStatementMarketingContent.content_type === 'both')">{{'Click to edit/view the image'}}</v-col>

                <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'img' || setting.pdfStatementMarketingContent.content_type === 'both')">{{ setting.pdfStatementMarketingContent.content_type === 'img'? 'Image will be displayed at the center by default' : 'Image will be displayed at ' + imagePosition + ' position'}}</v-col>
            </template>
            <template v-slot:edit>
                <div>
                    <hb-switch right v-model="setting.pdfStatementAllowMarketing" :label="setting.pdfStatementAllowMarketing ? 'Yes' : 'No'"></hb-switch>
                </div>
                <div v-if="setting.pdfStatementAllowMarketing">
                    <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing"> {{'Content Type'}} </v-col>
                    <hb-select
                        :items="contentTypeList"
                        v-model="setting.pdfStatementMarketingContent.content_type"
                        @change="handleContentTypeChange"
                        item-text="name"
                        item-value="value"
                        id="mktgContentType"
                        name="mktgContentType"
                        :clearable="false"
                        :error="errors.has('mktgContentType')"
                    ></hb-select>
                </div>
                <div v-if="setting.pdfStatementAllowMarketing">
                    <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing"> {{'Content Heading'}} </v-col>
                    <hb-text-field
                        v-model="setting.pdfStatementMarketingContent.content_heading"
                        id="mktgContentHeading"
                        name="mktgContentHeading"
                    >
                    </hb-text-field>
                </div>
                <div v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'text' || setting.pdfStatementMarketingContent.content_type === 'both')">
                    <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'text' || setting.pdfStatementMarketingContent.content_type === 'both')"> {{'Content Text'}} </v-col>
                    <hb-text-field
                        v-model="setting.pdfStatementMarketingContent.content_text"
                        id="mktgContentText"
                        name="mktgContentText"
                    >
                    </hb-text-field>
                </div>
                <div v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'img' || setting.pdfStatementMarketingContent.content_type === 'both')">
                    <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && (setting.pdfStatementMarketingContent.content_type === 'img' || setting.pdfStatementMarketingContent.content_type === 'both')"> {{'Content Image'}} </v-col>
                    <loader color="#8e44ad" size="30px" class="inline-loader" v-if="showLoader"></loader>
                    <div><v-img
                        :width="50"
                        :height="50"
                        :aspect-ratio="1"
                        contain
                        :src="setting.pdfStatementMarketingContent.img_url"
                        @load="showLoader=false"
                    ></v-img></div>
                    <v-col cols="12" sm="12" class="hb-form-edit-mode-main-padding-description"><hb-btn small color="primary" @click="showAdd = true">Upload Image</hb-btn></v-col>
                </div>
                <div v-if="setting.pdfStatementAllowMarketing && setting.pdfStatementMarketingContent.content_type === 'both'">
                    <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && setting.pdfStatementMarketingContent.content_type === 'both'"> {{'Content Image Position'}} </v-col>
                    <hb-select
                        :items="imgPosList"
                        v-model="setting.pdfStatementMarketingContent.img_pos"
                        item-text="name"
                        item-value="value"
                        id="mktgImagePos"
                        name="mktgImagePos"
                        :error="errors.has('mktgImagePos')"
                    ></hb-select>
                </div>
                <div v-if="setting.pdfStatementAllowMarketing && setting.pdfStatementMarketingContent.content_type === 'img'">
                    <v-col class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description" cols="12" sm="12" v-if="setting.pdfStatementAllowMarketing && setting.pdfStatementMarketingContent.content_type === 'img'"> {{'Content Image Position'}} </v-col>
                    <hb-select
                        readonly
                        placeholder="Center"
                        id="mktgImagePosCenter"
                        name="mktgImagePosCenter"
                        :error="errors.has('mktgImagePosCenter')"
                    ></hb-select>
                </div>
            </template>
          </hb-form>
        </div>
        <upload-file
            v-model="showAdd"
            v-if="showAdd"
            type="file"
            model="uploads"
            size="lg"
            slot="body"
            :file_types="['png']"
            foreign_id="mktg_content_img"
            @close="showAdd=false"
            @save="handleFileSave">
        </upload-file>
    </div>
</template>

<script type="text/babel">

import api from "../../../assets/api.js";
import UploadFile from '../../assets/UploadFile.vue';
import Loader from '../../assets/CircleSpinner.vue';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
    name: 'PdfStatementAttachment',
    data() {
        return {
            setting: {
                sendInvoicePdfAttachment: false,
                groupContactLeasesStatements: false,
                pdfStatementAllowMarketing: false,
                pdfStatementMarketingContent: {
                    id: '',
                    content_heading: '',
                    content_type: '',
                    content_text: '',
                    img_url: '',
                    img_pos: ''
                }
            },
            setting_cache: null,
            showAdd: false,
            showLoader: false,
            active: '',
            contentTypeList: [
                {name: 'Text', value: 'text'},
                {name: 'Image', value: 'img'},
                {name: 'Both Text & Image', value: 'both'}
            ],
            imgPosList: [
                {name: 'Top Left', value: 'top_left'},
                {name: 'Top Right', value: 'top_right'},
                {name: 'Top Center', value: 'top_center'},
                {name: 'Bottom Left', value: 'bottom_left'},
                {name: 'Bottom Right', value: 'bottom_right'},
                {name: 'Bottom Center', value: 'bottom_center'},
            ]

        };
    },
    async created() {
        if(this.setting.pdfStatementMarketingContent.img_url) this.showLoader = true;
        await this.getSettings();
    },
    props: ['facilityID'],
    mixins: [ notificationMixin ],
    components:{
        UploadFile,
        Loader
    },
    computed: {
        imagePosition(){
            return this.imgPosList.find(obj => obj.value === this.setting.pdfStatementMarketingContent.img_pos).name;
        },
        marketingContentTypeText(){
            return this.contentTypeList.find(obj => obj.value === this.setting.pdfStatementMarketingContent.content_type).name;
        }
    },
    methods: {
        handleEmittedCancel(){
            this.setting = JSON.parse(JSON.stringify(this.setting_cache));
        },
        async handleEmittedSave(label){
            try{
                await this.saveSetting(label);
                this.setting[label] = this.setting[label] ?? false;
                this.setting_cache[label] = this.setting[label] ?? false;
                if(label === 'sendInvoicePdfAttachment' && !this.setting.sendInvoicePdfAttachment){
                    this.setting.groupContactLeasesStatements = false;
                    this.setting_cache.groupContactLeasesStatements = false;
                }
                this.active = 'saved';
                this.showMessageNotification({type: 'success', description: `PDF statement setting have been saved.`});
            }catch(err){
                this.showMessageNotification({type: 'error', description: `Error: ${err}`});
            }
        },
        async handleEmittedSaveMarketing(){
            try{
                if(this.setting.pdfStatementAllowMarketing){
                    await this.saveMarketingDetails();
                }else{
                    this.setting.pdfStatementAllowMarketing = false;
                    if(this.setting_cache.pdfStatementAllowMarketing) await this.saveMarketingDetails();
                    this.setting.pdfStatementMarketingContent.content_heading = '';
                    this.setting.pdfStatementMarketingContent.content_type = '';
                    this.setting.pdfStatementMarketingContent.content_text = '';
                    this.setting.pdfStatementMarketingContent.img_url = '';
                    this.setting.pdfStatementMarketingContent.img_pos = '';

                }
                this.setting_cache = JSON.parse(JSON.stringify(this.setting));
                this.active = 'saved';
                this.showMessageNotification({type: 'success', description: `Marketing details have been saved.`});
            }catch(err){
                this.showMessageNotification({type: 'error', description: `Error: ${err}`});
            }
        },
        async saveSetting(label){
            let formData = {};
            if(label === 'sendInvoicePdfAttachment' && !this.setting[label]){
                formData = {
                    settings : { sendInvoicePdfAttachment: false, groupContactLeasesStatements: false  },
                };
            }else{
                formData = {
                    settings : { [label]: this.setting[label] ?? false },
                };
            }

            await api.post(this, api.SETTINGS + `billing${this.facilityID? `?property_id=${this.facilityID}` : ''}`, formData);
        },
        async saveMarketingDetails(){
            let formData = {};
            const previous_details_id = this.setting.pdfStatementMarketingContent.id;
            if(this.setting.pdfStatementAllowMarketing){
                formData = {
                    settings: {
                        content_heading: this.setting.pdfStatementMarketingContent.content_heading !== ''? this.setting.pdfStatementMarketingContent.content_heading : null,
                        content_type: this.setting.pdfStatementMarketingContent.content_type !== ''? this.setting.pdfStatementMarketingContent.content_type : null,
                        content_text: this.setting.pdfStatementMarketingContent.content_text !== ''? this.setting.pdfStatementMarketingContent.content_text : null,
                        img_url: this.setting.pdfStatementMarketingContent.img_url !== ''? this.setting.pdfStatementMarketingContent.img_url : null,
                        img_pos: this.setting.pdfStatementMarketingContent.img_pos !== ''? this.setting.pdfStatementMarketingContent.img_pos : null,
                        status: 1
                    },
                    previous_details_id,
                };
            }else {
                formData = {
                    settings: {
                        content_heading: null,
                        content_type: null,
                        content_text: null,
                        img_url: null,
                        img_pos: null,
                        status: 0
                    },
                    previous_details_id,
                }
            }

            let r = await api.post(this, api.SETTINGS + `marketing-details${this.facilityID? `?property_id=${this.facilityID}` : ''}`, formData);
            this.setting.pdfStatementMarketingContent.id = r.settings.id;
            this.setting_cache.pdfStatementMarketingContent.id = r.settings.id;
        },
        handleContentTypeChange(){
            if(this.setting.pdfStatementMarketingContent.content_type === 'text'){
                this.setting.pdfStatementMarketingContent.img_url = '';
                this.setting.pdfStatementMarketingContent.img_pos = '';
            }
            if(this.setting.pdfStatementMarketingContent.content_type === 'img'){
                this.setting.pdfStatementMarketingContent.content_text = '';
                this.setting.pdfStatementMarketingContent.img_pos = 'center';
            }
        },
        handleFileSave(response){
            this.setting.pdfStatementMarketingContent.img_url = response.src;
            this.showLoader = true;
        },
        async getSettings(){
            try{
                let billingSettings = await api.get(this.$app, api.SETTINGS + `?category=billing${this.facilityID? `&property_id=${this.facilityID}` : ''}`);
                if(billingSettings.settings?.length > 0) {
                    let allowPdfAttachment = billingSettings.settings.find(x=>x.name === 'sendInvoicePdfAttachment');
                    let groupContactStatements = billingSettings.settings.find(x=>x.name === 'groupContactLeasesStatements');

                    this.setting.sendInvoicePdfAttachment = allowPdfAttachment?.value? !!parseInt(allowPdfAttachment.value) : false;
                    this.setting.groupContactLeasesStatements = groupContactStatements?.value? !!parseInt(groupContactStatements.value) : false;
                }

                if(this.setting.sendInvoicePdfAttachment){
                    let mktgDetails = await api.get(this.$app, api.SETTINGS + `marketing-details${this.facilityID? `?property_id=${this.facilityID}` : ''}`);
                    let mktgDetailsSettings = mktgDetails.settings?.length > 0 ? mktgDetails.settings[0] : null;
                    if(mktgDetailsSettings){
                        this.setting.pdfStatementAllowMarketing = !!mktgDetailsSettings.status;
                        this.setting.pdfStatementMarketingContent.id = mktgDetailsSettings.id;
                        this.setting.pdfStatementMarketingContent.content_heading = mktgDetailsSettings.content_heading ?? '';
                        this.setting.pdfStatementMarketingContent.content_type = mktgDetailsSettings.content_type ?? '';
                        this.setting.pdfStatementMarketingContent.content_text = mktgDetailsSettings.content_text ?? '';
                        this.setting.pdfStatementMarketingContent.img_url = mktgDetailsSettings.img_url ?? '';
                        this.setting.pdfStatementMarketingContent.img_pos = mktgDetailsSettings.img_pos ?? '';
                    }

                }
                this.setting_cache = JSON.parse(JSON.stringify(this.setting));
            }catch(err){
                this.showMessageNotification({type: 'error', description: `Error: ${err}`});
            }
        }
	},
    watch: {
        facilityID(){
            this.getSettings();
        }
    }
};
</script>
<style scoped>
.hb-form-description-text-night-light {
    color: #637381;
}
.hb-form-edit-mode-main-padding-description {
    padding: 10.2px 16px 0px 0px;
}
</style>
