<template>
    <div class="pa-0">
        <div class="interaction-section-body1">
          <hb-form label="Allow Interproperty Payment" :active.sync="active" description="Allow Interproperty Payment" @cancel="handleEmittedCancel" @save="handleEmittedSave" editable :empty="false">
            <template v-slot:display>
              <v-col class="ma-0 pa-0" cols="12" sm="12">{{value ? 'Yes' : 'No'}}</v-col>
            </template>
            <template v-slot:edit>
              <div>
                <hb-switch right v-model="value" :label="value ? 'Yes' : 'No'"></hb-switch>
              </div>
            </template>
          </hb-form>
        </div>
      <!-- </div>
    </div> -->
    <hb-global-notification
      v-model="snackbar"
      :type="type"
      :list="type === 'error' ? errorsList : []"
      :description="description"
    >
    </hb-global-notification>
    <hb-modal
        size="medium"
        title="Disable Inter-Property Operations"
        v-model="confirmDisableInterPropertyOperations"
        @close="cancelDisableInterPropertyOperations"
        show-help-link
    >
        <template v-slot:content>
            <div style="height:200px;" class="pa-4">
                Disabling this feature would remove all the settings related to inter-property operations including the permissions assigned to users.
                <div class="pt-4">
                    Would you like to proceed?
                </div>
            </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn @click="disableInterPropertyOperations()">Disable</hb-btn>
        </template>
    </hb-modal>
    </div>
</template>

<script type="text/babel">

import api from "../../../assets/api.js";
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'InterpropertyPayment',
    data() {
        return {
            value: false,
            active: '',
            snackbar: false,
            type: '',
            description: '',
            confirmDisableInterPropertyOperations: false,
        };
    },
    async created() {
        this.getSettings();
    },
    computed: {
        ...mapGetters({
            getInterPropertyRoleId: 'authenticationStore/getInterPropertyRoleId',
            filteredProperties: 'propertiesStore/filtered',
            isInterPropertyPayment: 'propertiesStore/isInterPropertyPayment'
        }),
    },
    methods: {
        ...mapActions({
            setInterPropertySettings: 'propertiesStore/setInterPropertySettings',
            exchangeToken: 'propertiesStore/exchangeToken',
        }),
        setErrors(val) {
            if(val.length) {
                this.errorsList = val;
                this.showNotification();
            }
        },
        showNotification() {
            this.description = "There are errors in your form, correct them before continuing."
            this.type = 'error';
            this.snackbar = true;
            return;
        },
        hideNotification() {
            this.errorsList = [];
            this.description = ""
            this.type = 'cancel';
            this.snackbar = false;
        },
        handleEmittedCancel(label){
            this.hideNotification();
            this.getSettings();
        },
        async handleEmittedSave(label){
            const valid = await this.$validator.validateAll();
            if (!valid) {
                this.setErrors(this.errors);
            } else {
                if(this.errors.items.length) return;
                if(this.value) {
                    this.save();
                    this.active = 'saved';
                } else if(this.isInterPropertyPayment) {
                    this.confirmDisableInterPropertyOperations = true;
                } else {
                   this.active = 'saved'; 
                }
            }
        },
        async disableInterPropertyOperations() {
            this.confirmDisableInterPropertyOperations = false;
            this.save();
            this.active = 'saved';
            if(this.getInterPropertyRoleId) {
                await this.viewGroup();
            }
        },
        cancelDisableInterPropertyOperations() {
            if(this.confirmDisableInterPropertyOperations) {
                this.hideNotification();
                this.getSettings();
            }
        },
        async save() {
            let formData = {
                settings : { allowInterPropertyPayments: this.value },
                role_id : this.getInterPropertyRoleId
            };
            await api.post(this, api.SETTINGS +'billing', formData);
            await this.setInterPropertySettings();
        },
        async viewGroup(){
            const data = {
                property_ids:[]
            };
            let properties = [];
            properties = this.filteredProperties.length ? this.filteredProperties : [];
            properties.forEach(property => {
                data.property_ids.push(property.id);
            });
            let r = await this.exchangeToken(data);
            this.$parent.$emit('update:query', '');
            this.$store.dispatch('dashboardsStore/getDashboards');
            this.$store.dispatch('filterStore/clearSizes');
            this.$router.push(this.$router.history.current.path).catch(err => {});
            this.$nextTick(() => {
              this.$emit('close');
            })
            this.$router.go(this.$router.currentRoute)
        },
        async getSettings(){
            let billing = await api.get(this.$app, api.SETTINGS + '?category=billing');
            let value = false;
            if(billing.settings?.length > 0) {
                let settingObj = null;
                settingObj = billing.settings.find(x=>x.name === 'allowInterPropertyPayments');
                value = settingObj?.value;
                value = value === '1';
            }
            this.value = value;
        }
	},
    watch: {
        "errors.items"(val) {
        if(val.length) {
            this.setErrors(val);
        } else {
            this.hideNotification();
        }
        }
    }
};
</script>